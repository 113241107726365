import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import {useDispatch, useSelector, connect} from "react-redux";
export const AppViews = () => {

  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
          <Route path={`${APP_PREFIX_PATH}/ads`} component={lazy(() => import(`./ads`))} />
        <Route path={`${APP_PREFIX_PATH}/adminpages`} component={lazy(() => import(`./adminpages`))} />
        <Route path={`${APP_PREFIX_PATH}/usergroups`} component={lazy(() => import(`./usergroups`))} />
        <Route path={`${APP_PREFIX_PATH}/appsettings`} component={lazy(() => import(`./appsettings`))} />
        <Route path={`${APP_PREFIX_PATH}/coupons`} component={lazy(() => import(`./coupons`))} />
        <Route path={`${APP_PREFIX_PATH}/bets`} component={lazy(() => import(`./bets`))} />
        <Route path={`${APP_PREFIX_PATH}/oddtypes`} component={lazy(() => import(`./oddtypes`))} />
        <Route path={`${APP_PREFIX_PATH}/deposits`} component={lazy(() => import(`./deposits`))} />
        <Route path={`${APP_PREFIX_PATH}/messages`} component={lazy(() => import(`./messages`))} />
        <Route path={`${APP_PREFIX_PATH}/transactions`} component={lazy(() => import(`./transactions`))} />
        <Route path={`${APP_PREFIX_PATH}/usercontacts`} component={lazy(() => import(`./usercontacts`))} />
        <Route path={`${APP_PREFIX_PATH}/withdraws`} component={lazy(() => import(`./withdraws`))} />
        <Route path={`${APP_PREFIX_PATH}/categories`} component={lazy(() => import(`./categories`))} />
        <Route path={`${APP_PREFIX_PATH}/settings`} component={lazy(() => import(`./settings`))} />
        <Route path={`${APP_PREFIX_PATH}/dashboards`} component={lazy(() => import(`./dashboards`))} />
        <Route path={`${APP_PREFIX_PATH}/banners`} component={lazy(() => import(`./banners`))} />
        <Route path={`${APP_PREFIX_PATH}/currencies`} component={lazy(() => import(`./currencies`))} />
        <Route path={`${APP_PREFIX_PATH}/pairs`} component={lazy(() => import(`./pairs`))} />
        <Route path={`${APP_PREFIX_PATH}/accounts`} component={lazy(() => import(`./accounts`))} />
        <Route path={`${APP_PREFIX_PATH}/balances`} component={lazy(() => import(`./balances`))} />
        <Route path={`${APP_PREFIX_PATH}/orders`} component={lazy(() => import(`./orders`))} />
        <Route path={`${APP_PREFIX_PATH}/users`} component={lazy(() => import(`./users`))} />
        <Route path={`${APP_PREFIX_PATH}/languages`} component={lazy(() => import(`./languages`))} />
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/dashboards`} />
      </Switch>

    </Suspense>
  )
}

export default React.memo(AppViews);
