import {
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  CreditCardOutlined,
  FundOutlined,
  FlagOutlined,
  TabletFilled,
  CameraOutlined,
  VideoCameraOutlined,
  UserOutlined,
  SaveOutlined,
    ClockCircleOutlined,
  FormOutlined,
  HomeOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const finansNavTree = [
  {
    key: 'homepage',
    path: `${APP_PREFIX_PATH}/dashboards`,
    title: 'homepage',
    icon: HomeOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'coupons',
    path: `${APP_PREFIX_PATH}/coupons`,
    title: 'coupons',
    icon: FormOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'bets',
    path: `${APP_PREFIX_PATH}/bets`,
    title: 'bets',
    icon: FundOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'bets',
        path: `${APP_PREFIX_PATH}/bets`,
        title: 'bets',
        icon: FundOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'oddtypes',
        path: `${APP_PREFIX_PATH}/oddtypes`,
        title: 'oddtypes',
        icon: FundOutlined,
        breadcrumb: true,
        submenu: [],
      }
    ],
  },
  {
    key: 'deposits',
    path: `${APP_PREFIX_PATH}/deposits`,
    title: 'deposits',
    icon: PlusCircleOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'withdraws',
    path: `${APP_PREFIX_PATH}/withdraws`,
    title: 'withdraws',
    icon: SaveOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'users',
    path: `${APP_PREFIX_PATH}/users`,
    title: 'sidenav.users',
    icon: UserOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'users',
        path: `${APP_PREFIX_PATH}/users`,
        title: 'sidenav.users',
        icon: FlagOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'usergroups',
        path: `${APP_PREFIX_PATH}/usergroups`,
        title: 'usergroups',
        icon: FlagOutlined,
        breadcrumb: true,
        submenu: [],
      }
    ],
  },
  {
    key: 'languages',
    path: `${APP_PREFIX_PATH}/languages`,
    title: 'sidenav.languages',
    icon: FlagOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'ads',
    path: `${APP_PREFIX_PATH}/ads`,
    title: 'ads',
    icon: FlagOutlined,
    breadcrumb: true,
    submenu: [],
  },
  {
    key: 'settings',
    path: `${APP_PREFIX_PATH}/settings`,
    title: 'settings',
    icon: CompassOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'adminpages',
        path: `${APP_PREFIX_PATH}/adminpages`,
        title: 'adminpages',
        icon: FlagOutlined,
        breadcrumb: true,
        submenu: [],
      }
    ],
  }

  ]






const navigationConfig = [

  ...finansNavTree


]

export default navigationConfig;
